import React from 'react';
import './shared.css';
import { FaMapMarkerAlt, FaLinkedin, FaLongArrowAltRight } from 'react-icons/fa';

const CTA = () => {
  return (
    <section id="cta-section" className="cta-section">
      <div className="cta-overlay"></div>
      <div className="cta-container">
        <span className="section-badge">Who We Are</span>
        <h2 className="cta-heading">Ready to Scale Your Business?</h2>
        <div className="location-container">
          <div className="location-icon-group">
            <FaMapMarkerAlt className="location-icon west-coast" />
            <FaLongArrowAltRight className="arrow-between" />
            <FaMapMarkerAlt className="location-icon east-coast" />
          </div>
          <div className="location-content">
            <span className="location-text">Coast-to-Coast Canadian Team</span>
            <span className="location-subtext">From West to East, Serving All of North America</span>
          </div>
        </div>
        <div className="cta-content">
          <div className="cta-buttons">
            <a
              href="https://calendly.com/ben-thehumblecompany/30min"
              target="_blank"
              rel="noopener noreferrer"
              className="cta-button"
            >
              <span>Become a Partner</span>
              <svg className="arrow-icon" viewBox="0 0 24 24" fill="currentColor">
                <path d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
              </svg>
            </a>
            <a
              href="https://www.linkedin.com/in/ben-humble-b97035170/"
              target="_blank"
              rel="noopener noreferrer"
              className="linkedin-button"
            >
              <FaLinkedin className="linkedin-icon" />
              <span>Connect on LinkedIn</span>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CTA; 