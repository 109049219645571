import './App.css';
import './components/shared.css';
import Hero from './components/Hero';
import NavBar from './components/NavBar';
import ScrollProgress from './components/ScrollProgress';
import LoadingScreen from './components/LoadingScreen';
import PainPoints from './components/PainPoints';
import Partners from './components/Partners';
import EcosystemExplanation from './components/EcosystemExplanation';
import CTA from './components/CTA';
import Footer from './components/Footer';
import { useState, useEffect } from 'react';

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <div className="App">
      <ScrollProgress />
      <NavBar />
      <main>
        <Hero />
        <PainPoints />
        <Partners />
        <EcosystemExplanation />
        <CTA />
      </main>
      <Footer />
    </div>
  );
}

export default App;
