import React, { useState } from 'react';
import './Partners.css';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

const Partners = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const partners = [
    {
      name: "TeamBuilder Consulting",
      timeframe: "Less than 90 Days",
      impact: "$200K",
      metric: "Pipeline Generated",
      description: "Through our revenue share partnership model, we helped TeamBuilder Consulting rapidly expand their pipeline within just three months.",
    },
    {
      name: "MR&A",
      timeframe: "6 Months",
      impact: "$700K",
      metric: "Pipeline Generated",
      description: "Our partnership with MR&A demonstrates the power of our revenue share model, generating substantial pipeline growth in just six months.",
    }
  ];

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % partners.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev - 1 + partners.length) % partners.length);
  };

  return (
    <section id="partners" className="partners-section">
      <div className="container">
        <div className="section-header">
          <span className="section-badge">Success Stories</span>
          <h2>Our Partners</h2>
          <p className="section-subtitle">Real results through revenue-share partnerships</p>
        </div>

        <div className="testimonial-carousel">
          <button className="carousel-button prev" onClick={prevSlide} aria-label="Previous case study">
            <FaChevronLeft />
          </button>
          
          <div className="testimonial-container">
            {partners.map((partner, index) => (
              <div
                key={index}
                className={`testimonial-card ${index === currentSlide ? 'active' : ''}`}
                style={{ 
                  opacity: index === currentSlide ? 1 : 0,
                  transform: `translateX(${(index - currentSlide) * 100}%)`,
                  transition: 'all 0.5s ease'
                }}
              >
                <div className="impact-metric">
                  <span className="metric-value">{partner.impact}</span>
                  <span className="metric-label">{partner.metric}</span>
                  <span className="metric-timeframe">in {partner.timeframe}</span>
                </div>

                <div className="partner-info">
                  <h3>{partner.name}</h3>
                  <p className="partner-description">{partner.description}</p>
                </div>

                <div className="success-indicator">
                  <div className="indicator-line">
                    <div className="indicator-dot start"></div>
                    <div className="indicator-progress"></div>
                    <div className="indicator-dot end"></div>
                  </div>
                  <div className="timeline-labels">
                    <span>Partnership Start</span>
                    <span>{partner.timeframe}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <button className="carousel-button next" onClick={nextSlide} aria-label="Next case study">
            <FaChevronRight />
          </button>
        </div>

        <div className="carousel-dots">
          {partners.map((_, index) => (
            <button
              key={index}
              className={`dot ${index === currentSlide ? 'active' : ''}`}
              onClick={() => setCurrentSlide(index)}
              aria-label={`Go to case study ${index + 1}`}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Partners; 