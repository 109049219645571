import React from 'react';
import './PainPoints.css';

const PainPoints = () => {
  const painPoints = [
    {
      icon: 'scale',
      title: "Market Trust Crisis",
      points: [
        "Agencies with misaligned incentives prey on businesses' lack of expertise",
        "The market is flooded with noise, making it impossible to identify trustworthy partners",
        "Your competitors are gaining ground while you're stuck evaluating endless options"
      ]
    },
    {
      icon: 'trust',
      title: "Wasting Valuable Resources",
      points: [
        "Your marketing spend yields disappointing ROI as competitors thrive",
        "Each failed lead generation attempt costs you irreplaceable time",
        "The longer you wait, the more expensive the solution becomes"
      ]
    },
    {
      icon: 'resource',
      title: "Unable to Scale Growth",
      points: [
        "Unpredictable revenue makes hiring impossible and threatens existing staff",
        "Without reliable lead flow, you're caught in survival mode rather than growth",
        "Your window to capture market share is closing permanently"
      ]
    }
  ];

  return (
    <section id="reality-check" className="pain-points-section">
      <div className="container">
        <div className="section-header">
          <span className="section-badge">Reality Check</span>
          <h2>The Hidden Forces <span className="highlight">Killing Your Growth</span></h2>
          <p className="section-subtitle">For businesses doing $1M-$10M in revenue, these aren't just challenges. They're existential threats.</p>
        </div>

        <div className="pain-points-grid">
          {painPoints.map((point, index) => (
            <div key={index} className="pain-point-card">
              <div className="pain-point-icon">
                {renderIcon(point.icon)}
              </div>
              <h3>{point.title}</h3>
              <ul className="pain-point-list">
                {point.points.map((item, i) => (
                  <li key={i}>{item}</li>
                ))}
              </ul>
            </div>
          ))}
        </div>

        <div className="pain-points-cta">
          <p className="pain-hook">Putting a bandage on a wound isn't a solution—it's just delaying the inevitable.</p>
          <p className="solution-text">Stop treating symptoms. Start fixing the root cause. Our proven system turns these challenges into your competitive edge.</p>
        </div>
      </div>
    </section>
  );
};

const renderIcon = (iconName) => {
  switch (iconName) {
    case 'scale':
      return (
        <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
          <path d="M18 10h-4v4h4v-4zm-6 0H8v4h4v-4zm-6 0H2v4h4v-4zm12-6h-4v4h4V4zM6 4v4h4V4H6zm12 12h4v4h-4v-4z" />
        </svg>
      );
    case 'trust':
      return (
        <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
          <rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect>
          <path d="M7 11V7a5 5 0 0110 0v4"></path>
        </svg>
      );
    case 'resource':
      return (
        <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
          <circle cx="12" cy="12" r="10"></circle>
          <polyline points="12 6 12 12 16 14"></polyline>
        </svg>
      );
    default:
      return null;
  }
};

export default PainPoints; 