import React from 'react';
import './EcosystemExplanation.css';

function EcosystemExplanation() {
  const nodes = [
    {
      type: 'recruiters',
      label: 'Tech Recruiters',
      connections: ['humbleco', 'enterprise', 'startup'],
      position: { x: 20, y: 20 }
    },
    {
      type: 'enterprise',
      label: 'Enterprise',
      connections: ['humbleco', 'pe_firms', 'consultants'],
      position: { x: 80, y: 20 }
    },
    {
      type: 'consultants',
      label: 'Sales Consultants',
      connections: ['humbleco', 'enterprise', 'startup'],
      position: { x: 15, y: 40 }
    },
    {
      type: 'executive_coaches',
      label: 'Executive Coaches',
      connections: ['humbleco', 'enterprise', 'startup'],
      position: { x: 85, y: 40 }
    },
    {
      type: 'pe_firms',
      label: 'PE Firms',
      connections: ['humbleco', 'ma_advisors', 'funds'],
      position: { x: 25, y: 60 }
    },
    {
      type: 'ma_advisors',
      label: 'M&A Advisors',
      connections: ['humbleco', 'pe_firms', 'restructuring'],
      position: { x: 75, y: 60 }
    },
    {
      type: 'restructuring',
      label: 'Restructuring Firms',
      connections: ['humbleco', 'pe_firms', 'funds'],
      position: { x: 20, y: 80 }
    },
    {
      type: 'funds',
      label: 'Investment Funds',
      connections: ['humbleco', 'pe_firms', 'startup'],
      position: { x: 80, y: 80 }
    },
    {
      type: 'startup',
      label: 'Startups',
      connections: ['humbleco', 'consultants', 'funds'],
      position: { x: 50, y: 90 }
    }
  ];

  const getNodeConnections = (node) => {
    const connections = [];
    
    // Connection to HumbleCo
    connections.push(
      <div key="humbleco" className="connection-line to-humbleco">
        <div className="connection-particle"></div>
      </div>
    );

    // Connections to other nodes
    node.connections.forEach((target, i) => {
      if (target !== 'humbleco') {
        connections.push(
          <div key={target} className={`connection-line to-${target}`}>
            <div className="connection-particle"></div>
          </div>
        );
      }
    });

    return connections;
  };

  return (
    <section id="ecosystem" className="ecosystem-explanation-section">
      <div className="content-container">
        <div className="section-header">
          <span className="overline">Our Approach</span>
          <h2>The Ecosystem Model</h2>
          <p className="subtitle">
            A fundamentally different approach to lead generation that creates lasting value through strategic connections.
          </p>
        </div>

        <div className="ecosystem-map">
          <div className="central-hub">
            <div className="hub-pulse"></div>
            <div className="hub-content">
              <div className="hub-logo">
                <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.5">
                  <path d="M12 2L2 7l10 5 10-5-10-5zM2 17l10 5 10-5M2 12l10 5 10-5" />
                </svg>
              </div>
              <span>HumbleCo</span>
            </div>
          </div>

          {nodes.map((node, index) => (
            <div
              key={index}
              className={`ecosystem-node ${node.type}-node`}
              style={{
                left: `${node.position.x}%`,
                top: `${node.position.y}%`
              }}
            >
              <div className="node-content">
                <div className="node-icon">
                  {getNodeIcon(node.type)}
                </div>
                <span>{node.label}</span>
              </div>
              <div className="node-connections">
                {getNodeConnections(node)}
              </div>
            </div>
          ))}
        </div>

        <div className="pillars-grid">
          <div className="pillar-card">
            <div className="pillar-content">
              <span className="pillar-number">01</span>
              <h3>Form & Match</h3>
              <ul className="pillar-bullets">
                <li>Cut research time by 60% with AI-powered prospect matching</li>
                <li>Get matched with buyers actively seeking your solution</li>
                <li>Stop wasting time on poor-fit opportunities</li>
              </ul>
            </div>
          </div>

          <div className="pillar-card">
            <div className="pillar-content">
              <span className="pillar-number">02</span>
              <h3>Connect & Engage</h3>
              <ul className="pillar-bullets">
                <li>Turn cold leads into warm introductions in 24 hours</li>
                <li>Close deals 3x faster with pre-qualified connections</li>
                <li>Build lasting partnerships that grow your revenue</li>
              </ul>
            </div>
          </div>

          <div className="pillar-card">
            <div className="pillar-content">
              <span className="pillar-number">03</span>
              <h3>Scale & Succeed</h3>
              <ul className="pillar-bullets">
                <li>Multiply your network value with each connection</li>
                <li>Generate 5x more referrals from satisfied partners</li>
                <li>Create predictable, recurring revenue streams</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="metrics-section">
          <div className="metric-card">
            <span className="metric-value">60%</span>
            <span className="metric-label">Less Time Wasted on Unqualified Deals</span>
          </div>
          <div className="metric-card">
            <span className="metric-value">7x</span>
            <span className="metric-label">Return on Investment</span>
          </div>
          <div className="metric-card">
            <span className="metric-value">87%</span>
            <span className="metric-label">Faster Time-to-Revenue</span>
          </div>
        </div>

        <div className="features-section">
          <div className="feature-cards">
            <div className="feature-card">
              <div className="feature-icon">
                <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.5">
                  <path d="M13 10V3L4 14h7v7l9-11h-7z" />
                </svg>
              </div>
              <div className="feature-content">
                <h4>Automated Outreach</h4>
                <ul className="feature-bullets">
                  <li>Reach 100,000+ qualified prospects weekly</li>
                  <li>Get 87% faster response rates with smart targeting</li>
                  <li>Save 40 hours/week on manual prospecting</li>
                </ul>
              </div>
            </div>
            
            <div className="feature-card">
              <div className="feature-icon">
                <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.5">
                  <path d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
                </svg>
              </div>
              <div className="feature-content">
                <h4>Brand Protection</h4>
                <ul className="feature-bullets">
                  <li>Maintain 100% compliance with outreach rules</li>
                  <li>Build trust with professional, tested messaging</li>
                  <li>Keep a spotless sender reputation score</li>
                </ul>
              </div>
            </div>
            
            <div className="feature-card">
              <div className="feature-icon">
                <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.5">
                  <path d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              </div>
              <div className="feature-content">
                <h4>Revenue Acceleration</h4>
                <ul className="feature-bullets">
                  <li>Convert 40% more leads into paying customers</li>
                  <li>Reduce sales cycle length by 12 weeks</li>
                  <li>Boost average deal size by 2.5x</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

function getNodeIcon(type) {
  switch (type) {
    case 'enterprise':
      return (
        <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.5">
          <path d="M3 3h18v18H3V3zm9-1v20M8 3v4m0 4v4m0 4v4m8-16v4m0 4v4m0 4v4" />
        </svg>
      );
    case 'recruiters':
      return (
        <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.5">
          <path d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0z" />
        </svg>
      );
    case 'consultants':
      return (
        <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.5">
          <path d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
        </svg>
      );
    case 'executive_coaches':
      return (
        <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.5">
          <path d="M12 14l9-5-9-5-9 5 9 5z" />
          <path d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z" />
        </svg>
      );
    case 'pe_firms':
      return (
        <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.5">
          <path d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
      );
    case 'ma_advisors':
      return (
        <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.5">
          <path d="M8 7v8a2 2 0 002 2h6M8 7V5a2 2 0 012-2h4.586a1 1 0 01.707.293l4.414 4.414a1 1 0 01.293.707V15a2 2 0 01-2 2h-2M8 7H6a2 2 0 00-2 2v10a2 2 0 002 2h8a2 2 0 002-2v-2" />
        </svg>
      );
    case 'restructuring':
      return (
        <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.5">
          <path d="M9 7h6m0 10v-3m-3 3h.01M9 17h.01M9 14h.01M12 14h.01M15 11h.01M12 11h.01M9 11h.01M7 21h10a2 2 0 002-2V5a2 2 0 00-2-2H7a2 2 0 00-2 2v14a2 2 0 002 2z" />
        </svg>
      );
    case 'funds':
      return (
        <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.5">
          <path d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z" />
        </svg>
      );
    case 'startup':
      return (
        <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.5">
          <path d="M13 10V3L4 14h7v7l9-11h-7z" />
        </svg>
      );
    default:
      return null;
  }
}

export default EcosystemExplanation; 