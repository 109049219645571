import React, { useState, useEffect } from 'react';
import './Hero.css';

const Hero = () => {
  const [currentSubheading, setCurrentSubheading] = useState(0);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const subheadings = [
    "We Scale Your Business, You Keep Building",
    "Strategic Partnerships, Exponential Growth",
    "Connect, Collaborate, Conquer Markets",
    "Your Success is Our Success"
  ];

  useEffect(() => {
    // Remove the handleScroll function and event listener
    // Only keep the subheading rotation
    const rotateInterval = setInterval(() => {
      setCurrentSubheading(prev => (prev + 1) % subheadings.length);
    }, 3000);

    // Load Wistia scripts when video is shown
    if (showVideo) {
      const script1 = document.createElement('script');
      script1.src = 'https://fast.wistia.com/player.js';
      script1.async = true;
      document.head.appendChild(script1);

      const script2 = document.createElement('script');
      script2.src = 'https://fast.wistia.com/embed/41bkha2zae.js';
      script2.async = true;
      script2.type = 'module';
      document.head.appendChild(script2);

      const style = document.createElement('style');
      style.textContent = `
        wistia-player[media-id='41bkha2zae']:not(:defined) {
          background: center / contain no-repeat url('https://fast.wistia.com/embed/medias/41bkha2zae/swatch');
          display: block;
          filter: blur(5px);
          padding-top: 56.25%;
        }
      `;
      document.head.appendChild(style);

      return () => {
        document.head.removeChild(script1);
        document.head.removeChild(script2);
        document.head.removeChild(style);
      };
    }

    return () => {
      clearInterval(rotateInterval);
    };
  }, [showVideo]);

  const handleVideoClick = () => {
    setShowVideo(true);
  };

  const handleCloseVideo = () => {
    setShowVideo(false);
  };

  return (
    <>
      <section id="home" className="hero">
        <div className="hero-overlay"></div>
        <div className="hero-content">
          <div className="hero-grid">
            <div className="hero-left">
              <div className="hero-text">
                <h1>
                  <span className="gradient-text">Bridging</span> the Gap
                  <br />To Exponential Growth
                </h1>
                <div className="rotating-subheading">
                  <h2 className="fade-in-out">{subheadings[currentSubheading]}</h2>
                </div>
                <p className="hero-subtitle">
                  Partner with us to unlock your business potential. We connect you with the right organizations, 
                  scale your operations, and share in your success through our innovative revenue-share model.
                </p>
                <div className="hero-cta-container">
                  <a href="https://calendly.com/ben-thehumblecompany/30min" target="_blank" rel="noopener noreferrer" className="primary-cta">Become a Partner</a>
                  <button onClick={handleVideoClick} className="video-button">
                    <div className="video-button-content">
                      <svg className="play-icon" viewBox="0 0 24 24" fill="currentColor">
                        <path d="M8 5v14l11-7z"/>
                      </svg>
                      <span>Watch How It Works (3 min)</span>
                    </div>
                    <div className="video-button-border"></div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {showVideo && (
        <div className="video-modal" onClick={handleCloseVideo}>
          <div className="video-modal-content" onClick={e => e.stopPropagation()}>
            <button className="close-video-button" onClick={handleCloseVideo}>
              <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                <path d="M6 6L18 18M6 18L18 6" />
              </svg>
            </button>
            <wistia-player 
              media-id="41bkha2zae" 
              seo="false" 
              aspect="1.7777777777777777"
              wistia-popover="true"
            ></wistia-player>
          </div>
        </div>
      )}
    </>
  );
};

export default Hero; 